import React, { Component } from 'react'
import { OpeningJobSectionStyle } from './OpeningJobSection.styles'
import Button from 'components/Button/Button'
import Image from 'components/ImgOptimized'

class OpeningJobSection extends Component {
  render() {
    const { openingJobSecdProps } = this.props.openingJobSecdata
    const {
      data: {
        OpenJobImg: {
          childImageSharp: { fluid },
        },
      },
    } = this.props
    return (
      <OpeningJobSectionStyle>
        <div className="container">
          <div className="content-part">
            <h3>{openingJobSecdProps.heading}</h3>
            <Button
              buttonLink={openingJobSecdProps.buttonUrl}
              buttonName={openingJobSecdProps.buttonName}
            />
          </div>
          <div className="img-block">
            <Image
              fluid={fluid}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 528,
              }}
            />
          </div>
        </div>
      </OpeningJobSectionStyle>
    )
  }
}

export default OpeningJobSection
