import React from 'react'
import { AdvertiseBoxWrap, CultureCraftedStyle } from './CultureCrafted.style'

export default function CultureCrafted(props) {
  const { CultureCraftedData } = props

  return (
    <CultureCraftedStyle>
      <div className="container">
        <div className="heading-wrap">
          <h2>{CultureCraftedData.heading}</h2>
        </div>

        <p className="sub-text">{CultureCraftedData.subtext}</p>

        <AdvertiseBoxWrap>
          {CultureCraftedData.advertiseCards.map((advertiseCards, index) => (
            <div key={index} className="advertise-box-wrap">
              <h5
                className={`advertise-heading ${advertiseCards.dynamicClass}`}
              >
                {advertiseCards.heading}
              </h5>

              <p className="advertise-subText">{advertiseCards.subtext}</p>
              <span className="floating-icon">
                <img src={advertiseCards.icon} alt={advertiseCards.altText} />
              </span>
            </div>
          ))}
        </AdvertiseBoxWrap>
      </div>
    </CultureCraftedStyle>
  )
}
