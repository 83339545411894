import React from 'react'
import { images } from 'config/images'

export const AboutProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Partner in Innovation That Engineers Your{' '}
        <span className="heading-red">Next Digital Product</span>
      </>
    ),

    bannerContent: (
      <>
        Nearly every organization will need to become a tech company in order to
        compete tomorrow. Yes, even yours. At Simform, we are on a mission to
        help companies develop competitiveness and agility using the software.
        <br />
        <br />
        Simform is part of a rare league of engineering companies, uniquely
        positioned to build scalable software and deliver end-to-end product
        engineering services. We drive digital innovation through UX-led
        engineering, design thinking, product accelerators for efficient
        delivery, and strategic partnerships with leading cloud providers and AI
        platforms. We also serve as a system integrator with the ability to
        scale and achieve functional excellence.
        <br />
        <br />
        We have delivered product engineering services and developed scalable
        products for both SMEs and enterprises across diverse industries such as
        retail, healthcare, supply chain, and fintech, with our expertise in
        Cloud/MACH architectures, DevOps automation, data engineering, and
        advanced AI/ML.
      </>
    ),
    banner: images.dvdBanner,
    BannerAlt: 'About Simform',
  },

  CultureCraftedData: {
    heading: (
      <>
        <span className="highlight-head">Culture Crafted </span>
        by and for Engineers
      </>
    ),
    subtext: (
      <>
        Simform's culture empowers engineers to do their best work, resulting in
        a team of highly driven individuals who are passionate about their
        craft.
      </>
    ),
    advertiseCards: [
      {
        heading: 'Orlando, FL',
        subtext: 'Headquarters',
        icon: images.skyscapperIcon,
        altText: 'skyscapperIcon',
        dynamicClass: 'red-text',
      },
      {
        heading: '2010',
        subtext: 'Founded',
        icon: images.redFlagIcon,
        altText: 'redFlagIcon',
        dynamicClass: 'green-text',
      },
      {
        heading: '1300+',
        subtext: 'Employees',
        icon: images.bluePeople,
        altText: 'bluePeople',
        dynamicClass: 'yellow-text',
      },
      {
        heading: '350+',
        subtext: 'Certified Cloud Practitioners',
        icon: images.cloudCertifiedIcon,
        altText: 'cloudCertifiedIcon',
        dynamicClass: 'violet-text',
      },
    ],
  },
  EngineeringExcellenceData: {
    heading: (
      <>
        The Journey to{' '}
        <span className="highlight-head">Engineering Excellence</span>
      </>
    ),
    ExcellenceText: (
      <>
        Simform is more than just a software development company—we are partners
        in innovation. We started in 2010 with a major focus on offering mobile
        app development services, focusing on user experience and architecture.
        As we expanded into Web Apps and SaaS solutions, we acquired technical
        expertise in human-centered design, microservices, scalability, and data
        engineering.
        <br />
        <br />
        With a strong foundation in Microsoft and cloud technologies, we have
        now gained expertise in product engineering and digital innovation,
        combined with strong emphasis on app modernization, cloud solutions,
        DevOps, and streamlined delivery practices that ensure agility and
        reliability across complex projects. Our mission to drive digital
        innovation sets us apart as a system integrator and partner for
        innovation, committed to delivering excellence and optimizing
        scalability.
      </>
    ),
    ExcellenceImage: [{ excellenceImage: images.excellenceImage }],
  },
  BrandsLogoData: {
    companyHeading: (
      <>
        Trusted by the World's{' '}
        <span className="highlight-head">Leading Companies</span>
      </>
    ),
    awardHeading: (
      <>
        We are <br /> <span className="highlight-head">Recognized </span>
        by
      </>
    ),
    clientLogos: [
      {
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrcg: images.ciscoLogo,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrcg: images.bankOfAmericaLogo,
        clientLogoAlt: 'Bank Of America',
      },
      {
        clientLogosrcg: images.cameoCLogo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    awardBlockProps: [
      {
        awardBlockImg: images.mspDataAiAzure2x,
        awardBlockWidth: 200,
        awardBlockHeight: 122,
        dynamicClass: 'image-one',
      },
      {
        awardBlockImg: images.databricksLogo,
        awardBlockWidth: 88,
        awardBlockHeight: 111,
        dynamicClass: 'image-four',
      },
      {
        awardBlockImg: images.cmmiLevel3,
        awardBlockWidth: 107,
        awardBlockHeight: 80,
        dynamicClass: 'image-five',
      },
      {
        awardBlockImg: images.mspDigitalAppInnovationAzure,
        awardBlockWidth: 200,
        awardBlockHeight: 122,
        dynamicClass: 'image-two',
      },
      {
        awardBlockImg: images.theAmericanBusinessAwards,
        awardBlockWidth: 87,
        awardBlockHeight: 98,
        dynamicClass: 'image-six',
      },
      {
        awardBlockImg: images.globalOutsourcing100,
        awardBlockWidth: 138,
        awardBlockHeight: 44,
        dynamicClass: 'image-seven',
      },
      {
        awardBlockImg: images.mspInfrastructureAzure,
        awardBlockWidth: 200,
        awardBlockHeight: 122,
        dynamicClass: 'image-three',
      },
      {
        awardBlockImg: images.clutchImage,
        awardBlockWidth: 83,
        awardBlockHeight: 71,
        dynamicClass: 'image-eight',
      },
      {
        awardBlockImg: images.g2Image,
        awardBlockWidth: 76,
        awardBlockHeight: 71,
        dynamicClass: 'image-nine',
      },
    ],
  },
  developmentStackProps: {
    heading: (
      <>
        We are your <span className="highlight-head">development stack</span>
      </>
    ),
    mainPara: (
      <>
        You run your business. We take care of your development needs. Get
        access to an entire team of experts, ready{' '}
        <span className="color-through">whenever you need us.</span>
      </>
    ),
    stackListBlock: [
      {
        title: (
          <>
            Accelerate <br />
            Digital Innovation
          </>
        ),
        stackList: [
          'Digital Transformation',
          'Custom Software',
          'Business Applications',
          'Software Products',
          'API/System Integration',
          'Mobile Apps',
          'SaaS',
          'Consumer Apps',
        ],
      },
      {
        title: (
          <>
            Extend <br />
            Tech Capacity
          </>
        ),
        stackList: [
          'Proven Processes ',
          'Remote Team Culture',
          'QA & Testing',
          'Mentors and Tech Leads',
          'Code Quality',
          'Effortless Hiring at Scale',
          'Guaranteed Results',
          'Security',
          'Tools and Cloud Servicing',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Business Goals
          </>
        ),
        stackList: [
          'Your Tech Team',
          'Digital Transformation Plans',
          'Product Development',
          'System Integration',
          'Modernization',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Organization
          </>
        ),
        stackList: [
          'Sales & Marketing',
          'Operations',
          'Human Resource',
          'Finance',
          'Production',
          'Research and Development',
        ],
      },
    ],
  },
  leadershipTeamProps: {
    heading: (
      <>
        Leadership <span className="highlight-head">Team</span>
      </>
    ),
    teamListProps: [
      {
        memberListProps: [
          {
            name: 'Prayaag Kasundra',
            designation: '— CEO',
          },
          {
            name: 'Hiren Dhaduk',
            designation: '— CTO',
          },
          {
            name: 'Hardik Shah',
            designation: '— Head, Sales',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Maitrik Kataria',
            designation: '— CEO, North America',
          },
          {
            name: 'Justin Mitchell',
            designation: 'COO USA',
          },
          {
            name: 'Abhi Dahagam',
            designation: '— Head, AWS',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Dhvanil Desai',
            designation: '- VP, Delivery (AWS)',
          },
          {
            name: 'Mihir Shah',
            designation: '- VP, Delivery',
          },
          {
            name: 'Tejas Kaneriya',
            designation: '— Head, Business Analysis',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Rohit Akiwatkar',
            designation: '— Director of Marketing',
          },
        ],
      },
    ],
  },
  openingJobSecdProps: {
    heading: <>Choose your own growth path</>,
    buttonName: 'Explore our openings',
    buttonUrl: '/careers/',
  },
  mealmenuSectionProps: {
    heading: <>Unleash your potential</>,
    menuListing: [
      {
        singleBlock: [
          {
            icon: images.iconHealthBenefits,
            title: 'Health Benefits',
            content: (
              <>
                We work best when the people we care are healthy. Our{' '}
                <strong>health program</strong> empowers you to be worry-free.
              </>
            ),
          },
          {
            icon: images.iconMentorshipAnd,
            title: 'Mentorship and Learning',
            content: (
              <>
                Never stop learning by getting an online or{' '}
                <strong>in-person mentoring</strong> on ANY topic you want to
                grow in.
              </>
            ),
          },
        ],
      },
      {
        singleBlock: [
          {
            icon: images.iconFlexibility,
            title: 'Flexibility to work',
            content: (
              <>
                Stay <strong>inspired to work</strong> however and whatever
                technologies you find your best productive self in.
              </>
            ),
          },
          {
            icon: images.iconLevelUp,
            title: 'Level up career',
            content: (
              <>
                Work with internationally recognized clients and take ownership
                of how you <strong>work to build things.</strong>
              </>
            ),
          },
        ],
      },
    ],
    buttonUrl: '/careers/',
    buttonName: 'Explore our openings',
  },
  CTACard: {
    title: <>Get in Touch to Discuss Your Requirements.</>,
    buttonName: 'Contact Us',
    buttonLink: '/contact/',
  },
}
