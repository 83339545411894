import React, { Component } from 'react'
import { MealMenuSectionStyle } from './MealMenuSection.styles'
import Image from 'components/ImgOptimized'
import Button from 'components/Button/Button'

class MealMenuSection extends Component {
  render() {
    const { mealmenuSectionProps } = this.props.mealmenusecData
    const {
      data: {
        meal1: {
          childImageSharp: { fluid: mealImg1 },
        },
        meal2: {
          childImageSharp: { fluid: mealImg2 },
        },
        meal3: {
          childImageSharp: { fluid: mealImg3 },
        },
        work1: {
          childImageSharp: { fluid: work1 },
        },
        work2: {
          childImageSharp: { fluid: work2 },
        },
        work3: {
          childImageSharp: { fluid: work3 },
        },
      },
    } = this.props
    let item = [
      { imgLeft: mealImg1, imgRight: mealImg2, imgBottom: mealImg3 },
      { imgLeft: work1, imgRight: work2, imgBottom: work3 },
    ]

    return (
      <MealMenuSectionStyle>
        <div className="container">
          <div className="heading-wrapper">
            <h3>{mealmenuSectionProps.heading}</h3>
          </div>
          <div className="menu-listing-row">
            {mealmenuSectionProps.menuListing.map((menuListingProp, i) => {
              return (
                <div key={i} className="single-row">
                  <div className="content-blk">
                    {menuListingProp.singleBlock.map((singleBlockProp, i) => {
                      return (
                        <div key={`${i}${i}`} className="content-row">
                          <div className="icon">
                            <img src={singleBlockProp.icon} alt="" />
                          </div>
                          <h4>{singleBlockProp.title}</h4>
                          <p>{singleBlockProp.content}</p>
                        </div>
                      )
                    })}
                  </div>
                  <div className="img-block">
                    <div className="col-right">
                      <div className="col-left">
                        <Image
                          fluid={item[i].imgLeft}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 377,
                          }}
                        />
                      </div>
                      <div className="row">
                        <Image
                          fluid={item[i].imgRight}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 377,
                          }}
                        />
                      </div>
                      <div className="row">
                        <Image
                          fluid={item[i].imgBottom}
                          lazyload={{
                            enabled: true,
                            once: true,
                            offset: 1000,
                            heigth: 306,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="btn-row">
            <Button
              buttonLink={mealmenuSectionProps.buttonUrl}
              buttonName={mealmenuSectionProps.buttonName}
            />
          </div>
        </div>
      </MealMenuSectionStyle>
    )
  }
}

export default MealMenuSection
