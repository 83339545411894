import styled from 'styled-components'
import { lg } from 'config/variables'

export const EngineeringExcellenceStyle = styled.section`
  .excellence-wrap {
    display: flex;
    align-items: center;
    gap: 30px;
    ${lg(`
   flex-direction:column;
   gap:4px;
      `)}
    .excellence-left {
      max-width: 650px;
      flex: 0 1 650px;
      color: #3d3d3d;
      font-family: Graphik;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      ${lg(`
    flex: 1 1 auto;
        `)}
    }
    .excellence-right {
      max-width: 460px;
      flex: 0 1 460px;
      width: 100%;
      ${lg(`
    flex: 1 1 auto;
    margin-bottom:20px;
        `)}
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
`
