import styled from 'styled-components'
import { lg, md, sm } from 'config/variables'

export const OpeningJobSectionStyle = styled.section`
  padding: 50px 0;
  margin: 0 0 58px;
  ${md(`
    padding: 30px 0;
  `)}
  ${sm(`
    margin: 0 0 38px;
  `)}
  .container {
    display: flex;
    align-items: center;
    ${md(`
      flex-wrap: wrap;
      justify-content: center;
    `)}
    .content-part {
      max-width: 41%;
      width: 100%;
      text-align: center;
      ${lg(`
        max-width: 49%;
      `)}
      ${md(`
        max-width: 100%;
      `)}
      h3 {
        margin-bottom: 40px;
        ${lg(`
          font-size: 36px;
          line-height: 44px;
        `)}
      }
    }
    .img-block {
      text-align: center;
      max-width: 59%;
      width: 100%;
      ${md(`
        margin-top: 30px;
        max-width: 500px;
      `)}
    }
  }
`
