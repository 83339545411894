import styled from 'styled-components'
import { xl, lg, sm, md, sh } from 'config/variables'

export const TrustedByCompanyStyle = styled.section`
  background-color: #fafbff;
  padding: 20px 0 38px;
  .d-flex {
    display: flex;
    gap: 100px;
    ${xl(`
      flex-direction: column;
    `)}
    ${lg(`
      flex-direction: column;
    `)}
  }
  .section-heading{
    margin-bottom: 40px;
  }
  .companies-wrap{
    .logoImgBlock {
      max-width: 550px;
      flex: 0 1 550px;
      display: flex;
      flex-wrap: wrap;
      ${xl(`
        flex: 0 1 auto;
         max-width:100%;
      `)}
      ${lg(`
        flex: 1 1 auto; 
        max-width:100%;
      `)} 
      .logo-wrap {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 142px;
        flex: 0 0 25%;
        width: 25%;
        max-width: 25%;
        border-right: 1px solid #dae3f6;
        border-bottom: 1px solid #dae3f6;
        ${lg(`
          min-height: 120px;
        `)}
        ${sm(`
          flex: 0 0 50%;
          width: 50%;
          max-width: 50%;
          min-height: 100px;
        `)}
        &:nth-child(4n + 0) {
          border-right: 0;
          ${sm(`
            border-right: 1px solid #dae3f6;
          `)}
        }
        &:nth-child(2n + 0) {
          ${sm(`
            border-right: 0; 
          `)}
        }
        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          border-bottom: 0;
        }
        &:nth-last-child(4),
        &:nth-last-child(3) {
          border-bottom: 0;
          ${sm(`
            border-bottom: 1px solid #dae3f6; 
          `)}
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .recognize-wrap {
    .grid-container {
      width: 470px;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding: 10px;
      ${xl(`
         width: 100%;
    `)}
      ${lg(`
       width: 100%;
    `)}
      ${md(`
       width: 100%;
          grid-template-columns: repeat(1, 1fr);
    `)}
    }

    .grid-item {
      object-fit: contain;
      max-width: unset;
      height: revert-layer;
    }
    .image-one{
          ${md(`
          order:1;
        `)}
    }
    .image-two{
          ${md(`
          order:2;
        `)}
    }
    .image-three{
          ${md(`
          order:3;
        `)}
    }
    .image-four{
          ${md(`
          order:4;
        `)}
    }
    .image-five{
          ${md(`
          order:5;
        `)}
    }
    .image-six{
          ${md(`
          order:6;
        `)}
    }
    .image-seven{
          ${md(`
          order:7;
        `)}
    }
    .image-eight{
          ${md(`
          order:8;
        `)}
    }
    .image-nine{
          ${md(`
          order:9;
        `)}
    }
  }
`
