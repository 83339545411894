import styled from 'styled-components'
import { lg, sm, md } from 'config/variables'

export const CultureCraftedStyle = styled.section`
  .heading-wrap {
    font-size: 44px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.478px;
  }
  .sub-text {
    color: #3d3d3d;
    font-family: Graphik;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 46px;
  }
`
export const AdvertiseBoxWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  ${lg(`
         gap: 40px;
           grid-template-columns: repeat(2, 1fr);
        `)}
  ${md(`
      flex-wrap: wrap;
           grid-template-columns: repeat(1, 1fr);
        `)}
  ${sm(`
      flex-wrap: wrap;
           grid-template-columns: repeat(1, 1fr);
        `)}
  .advertise-box-wrap {
    padding: 26px 21px;
    background-color: #f5f7ff;
    border-radius: 4px;
    position: relative;
    width: 100%;
    .advertise-heading {
      font-family: 'Circular Std';
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    .red-text{
      color:#EF5366;
    }
    .green-text{
      color:#359F9A;
    }
    .yellow-text{
      color:#E1B78B;
    }
    .violet-text{
      color:#4E66D3;
    }
    .advertise-subText {
      font-size: 24px;
      font-style: normal;
      font-weight: 450;
      line-height: normal;
      color: #3d3d3d;
    }
    .floating-icon {
      position: absolute;
      right: 0;
      top: -22px;
    }
  }
`
