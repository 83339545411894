import React from 'react'
import { TrustedByCompanyStyle } from './TrustedByCompanies.style'

export default function TrustedByCompanies(props) {
  const { BrandsLogoData } = props

  return (
    <TrustedByCompanyStyle>
      <div className="container">
        <div className="d-flex">
          <div className="companies-wrap">
            <h2 className="section-heading">{BrandsLogoData.companyHeading}</h2>
            <div className="logoImgBlock">
              {BrandsLogoData.clientLogos.map((clientLogo, i) => {
                return (
                  <div key={i} className="logo-wrap">
                    <a
                      href={clientLogo.clientLogoLink}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="logo-block"
                    >
                      <img
                        className="hvr-logo"
                        src={clientLogo.clientLogosrcg}
                        alt={clientLogo.clientLogoAlt}
                      />
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="recognize-wrap">
            <h2 className="section-heading">{BrandsLogoData.awardHeading}</h2>
            <div className="grid-container">
              {BrandsLogoData.awardBlockProps.map((awardBlockProps, i) => (
                <img
                  key={i}
                  src={awardBlockProps.awardBlockImg}
                  alt={`img-${i}`}
                  className={`grid-item ${awardBlockProps.dynamicClass}`}
                  width={awardBlockProps.awardBlockWidth}
                  height={awardBlockProps.awardBlockHeight}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </TrustedByCompanyStyle>
  )
}
