import React from 'react'
import { EngineeringExcellenceStyle } from './EngineeringExcellence.style'
import Image from 'components/ImgOptimized'

export default function EngineeringExcellence(props) {
  const { EngineeringExcellenceData } = props
  const {
    data: {
      excellenceImage: {
        childImageSharp: { fluid: excellenceImages },
      },
    },
  } = props

  return (
    <EngineeringExcellenceStyle>
      <div className="container">
        <div className="heading-wrap">
          <h2>{EngineeringExcellenceData.heading}</h2>
        </div>
        <div className="excellence-wrap">
          <div className="excellence-left">
            <p>{EngineeringExcellenceData.ExcellenceText}</p>
          </div>
          <div className="excellence-right">
            <Image
              fluid={excellenceImages}
              alt={EngineeringExcellenceData.heading}
              lazyload={{
                enabled: false,
              }}
            />
          </div>
        </div>
      </div>
    </EngineeringExcellenceStyle>
  )
}
